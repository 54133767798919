import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";

const AllProducts = ({ bannArray }) => {
  const [htmlData, setHtmlData] = useState("");

  useEffect(() => {
    if (bannArray) {
      const filteredData = bannArray.filter((obj) => obj.rec_type === "html");
      if (filteredData.length > 0) {
        setHtmlData(DOMPurify.sanitize(filteredData[0].html));
      }
    }
  }, [bannArray]);

  return (
    <>
      <style jsx global>{`
        #allproducts * > * {
          font-family: JustSans, sans-serif !important;
        }
        a[href="/shopbyproducts"].xs\:text-\[16px\]:hover {
          font-family: Graphik, sans-serif !important;
          color: #ffffff;
          background-color: #604bcc;
          transition: 0.3s;
        }
      `}</style>
      <div
        id="allproducts"
        dangerouslySetInnerHTML={{ __html: htmlData }}
      ></div>
    </>
  );
};

export default AllProducts;
