import React, { useState } from "react";
import dynamic from "next/dynamic";
const SuperFavCard = dynamic(() => import("./SuperFavCard"));
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Thumbs, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

const SuperFavHome = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <>
      <div className="xs:mt-[60px] sm:mt-[60px] md:mt-[100px] lg:mt-[100px]">
        <div className="text-center max-w-[800px] mx-auto xs:mb-[25px] sm:mb-[25px] md:mb-[40px] lg:mb-[40px]">
          <div className="xs:text-[24px] sm:text-[24px] md:text-[34px] lg:text-[34px] text-brand-charcoal avenir-black uppercase mb-[6px] font-bold">
            Trending Now
          </div>
          <div className="text-[16px] avenir-roman text-brand-charcoal">
            Explore what's trending today
          </div>
        </div>
        {props.supfavarray?.length > 1 ? (
           <div className="w-full max-w-[1450px] mx-auto common-slider">
           <Swiper
             slidesPerView={4}
             grabCursor={true}
             spaceBetween={20}
             keyboard={true}
             autoplay={false}
             direction="horizontal"
                 mousewheel={{
                   thresholdTime: 500,
                   forceToAxis: true,
                 }}
             // pagination={{
             //   clickable: false,
             //   enabled: false,
             //   horizontalClass: "hidden",
             // }}
             pagination={true}
             breakpoints={{
               640: {
                 slidesPerView: 4,
               },
               414: {
                 slidesPerView: 2,
               },
               300: {
                 slidesPerView: 2,
               },
             }}
             modules={[Pagination, Thumbs, Navigation, Mousewheel]}
             onSwiper={setSwiper}
             onSlideChange={(swiper) => {
               setSlideIndex(swiper.activeIndex);
             }}
             className="flex justify-center flex-row items-center relative"
           >
             {props.supfavarray &&
               props.supfavarray.length > 1 &&
               props.supfavarray?.map((obj, i) => {
                 return (
                   <SwiperSlide key={i}>
                     <SuperFavCard
                       url={obj.handle}
                       src={obj.image + "?tr=w-410,h-570,c-at_max"}
                       title={obj.title}
                     />
                   </SwiperSlide>
                 );
               })}
           </Swiper>
         </div>       
        ) : (
        //   <div className="flex w-full flex-wrap justify-center">
        //   <Spinner />
        // </div>
        ""
        )}
        
      </div>
    </>
  );
};

export default SuperFavHome;
