import React from "react";
import AllProducts from "./AllProducts";
import { Spinner } from "@material-tailwind/react";

const ProductsHome = ({bannArray}) => {
  
  return (
    <>
      <div className="xs:mt-[60px] sm:mt-[60px] md:mt-[100px] lg:mt-[100px]">
        <div className="text-center max-w-[800px] mx-auto xs:mb-[25px] sm:mb-[25px] md:mb-[40px] lg:mb-[40px]">
          <div className="xs:text-[24px] sm:text-[24px] md:text-[34px] lg:text-[34px] text-brand-charcoal avenir-black uppercase mb-[6px] font-bold">
            Shop By Products
          </div>
          <div className="text-[16px] avenir-roman text-brand-charcoal">
            Categories we all think you will love shopping
          </div>
        </div>
        
        {bannArray?.length > 1 ? (
          <div>
            <AllProducts bannArray={bannArray}/>
          </div>
         ) : (
           ""
        )}
      </div>
    </>
  );
};

export default ProductsHome;
