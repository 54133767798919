import React,{useState,useEffect} from 'react'
import Link from 'next/link'
import Image from 'next/image'

const SpotlightBanner = ({bannArray}) => {
    
    // console.log(bannArray);

  return (
    <>
    {bannArray?.filter(k=>k.type==="spotlight" && k.rec_type==="image").map((obj,i)=>{

    
       return(<div className='xs:mt-[60px] sm:mt-[60px] md:mt-[100px] lg:mt-[100px]' key={i}>         
            <div className="text-center max-w-[800px] mx-auto xs:mb-[25px] sm:mb-[25px] md:mb-[40px] lg:mb-[40px]">
                <div className="xs:text-[24px] sm:text-[24px] md:text-[34px] lg:text-[34px] text-brand-charcoal avenir-black uppercase mb-[6px] font-bold">
                    In the Spotlight
                </div>
                {/* <div className="text-[16px] avenir-roman text-brand-charcoal">
                    Buy the best cases for your devices
                </div> */}
            </div>
            <div className='xs:hidden sm:hidden md:block lg:block'>          
                <Link href={obj.handle}>
                    <Image
                unoptimized src={obj.web_image} width={1950} height={550} alt={obj.title} />
                </Link>
            </div>
            <div className='xs:block sm:block md:hidden lg:hidden'>
                <Link href={obj.handle}>
                    <Image
                unoptimized src={obj.mobile_image} width={511} height={760} alt={obj.title} />
                </Link>
            </div>
        </div>) })
}
    </>
  )
}

export default SpotlightBanner
